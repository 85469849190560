
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from "vue-router"
import { APPModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user'
import { ElMessageBox } from 'element-plus';
import { ElMessage } from 'element-plus';
import { ElLoading } from 'element-plus';
import request from '@/plugin/request';
export default defineComponent({
    setup () {

        const route = useRoute();
        const router = useRouter();
        const client = ref('');


        document.onkeydown = (e) => {

            if (e.ctrlKey && e.altKey && e.key === 'd') {
                ElMessageBox.prompt('请输入开发秘钥', '开发模式', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: false,
                    inputType: 'password'
                })
                    .then(({ value }) => {

                        if (value === 'jijia') {
                            UserModule.login({ mode: 'dev' }).then((res) => { router.push({ path: '/', query: {} }) })
                        }

                    })
                    .catch(() => { ElMessage({ type: 'info', message: '取消输入', }) });
            }

        }

        nextTick(() => {

        })

        onMounted(() => {
            console.log('解析参数')

            console.log(route.query)

            if (route.query.token && route.query.token === 'jijia') {
                UserModule.login({ mode: 'dev' }).then((res) => { router.push({ path: '/', query: {} }) })
            }

            if (route.query.code) {

                let loading = ElLoading.service({ fullscreen: true });
                UserModule.login({ code: route.query.code }).then((res) => {
                    router.push({ path: '/', query: {} }).then(() => { loading.close() })
                })

            } else {

                if (/wxwork/.test(navigator.userAgent)) {
                    client.value = '企业微信'
                } else {
                    client.value = '桌面端'
                }

            }



        })

        return {
            fullTitle: APPModule.fullTitle,
            subTitle: APPModule.subTitle,
            copyright: APPModule.copyright,
            client,
        };
    },
    components: {

    },
});
